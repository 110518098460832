.home {
   background-image: url('../images/sidebar-icons/home.svg');
}

.diamonds {
   background-image: url('../images/sidebar-icons/diamond.svg');
}

.upload {
   background-image: url('../images/sidebar-icons/upload.svg');
}

.reports {
   background-image: url('../images/sidebar-icons/reports.svg');
}

.flyers {
   background-image: url('../images/sidebar-icons/flyers.svg');
}

.survey {
   background-image: url('../images/sidebar-icons/survey.svg');
}

.store-locations {
   background-image: url('../images/sidebar-icons/store-locations.svg');
}
.help-center {
   background-image: url('../images/sidebar-icons/chatbot-icon.svg');
}
.administration {
   background-image: url('../images/sidebar-icons/administration.svg');
}
.stores {
   background-image: url('../images/sidebar-icons/stores.svg');
}

.item {
   background-image: url('../images/sidebar-icons/items.svg');
}

.vendors {
   background-image: url('../images/sidebar-icons/vendors.svg');
}

.sales-rep {
   background-image: url('../images/sidebar-icons/sales-rep.svg');
}

.sales-regions {
   background-image: url('../images/sidebar-icons/sales-region.svg');
}

.about {
   background-image: url('../images/sidebar-icons/about.svg');
}

.sidenav-nav-item {
   &:hover {
      .home {
         background-image: url('../images/sidebar-icons/home-blue.svg');
      }

      .diamonds {
         background-image: url('../images/sidebar-icons/diamond-blue.svg');
      }

      .upload {
         background-image: url('../images/sidebar-icons/upload-blue.svg');
      }

      .reports {
         background-image: url('../images/sidebar-icons/reports-blue.svg');
      }

      .flyers {
         background-image: url('../images/sidebar-icons/flyer-blue.svg');
      }

      .survey {
         background-image: url('../images/sidebar-icons/survey-blue.svg');
      }

      .store-locations {
         background-image: url('../images/sidebar-icons/store-locations-blue.svg');
      }
      .help-center {
         background-image: url('../images/sidebar-icons/chatbot-iconblue.svg');
      }
      .administration {
         background-image: url('../images/sidebar-icons/administrationblue.svg');
      }
      .stores {
         background-image: url('../images/sidebar-icons/stores-blue.svg');
      }

      .item {
         background-image: url('../images/sidebar-icons/items-blue.svg');
      }

      .vendors {
         background-image: url('../images/sidebar-icons/vendors-blue.svg');
      }

      .sales-rep {
         background-image: url('../images/sidebar-icons/sales-rep-blue.svg');
      }

      .sales-regions {
         background-image: url('../images/sidebar-icons/sales-regions-blue.svg');
      }

      .about {
         background-image: url('../images/sidebar-icons/about-blue.svg');
      }
   }

   .active {
      .home {
         background-image: url('../images/home-blue.svg');
      }

      .diamonds {
         background-image: url('../images/sidebar-icons/diamond-blue.svg');
      }

      .upload {
         background-image: url('../images/sidebar-icons/upload-blue.svg');
      }

      .reports {
         background-image: url('../images/sidebar-icons/reports-blue.svg');
      }

      .flyers {
         background-image: url('../images/sidebar-icons/flyer-blue.svg');
      }

      .survey {
         background-image: url('../images/sidebar-icons/survey-blue.svg');
      }
      .store-locations {
         background-image: url('../images/sidebar-icons/store-locations-blue.svg');
      }
      .help-center {
         background-image: url('../images/sidebar-icons/chatbot-iconblue.svg');
      }
      .administration {
         background-image: url('../images/sidebar-icons/administrationblue.svg');
      }
      .stores {
         background-image: url('../images/sidebar-icons/stores-blue.svg');
      }

      .item {
         background-image: url('../images/sidebar-icons/items-blue.svg');
      }

      .vendors {
         background-image: url('../images/sidebar-icons/vendors-blue.svg');
      }

      .sales-rep {
         background-image: url('../images/sidebar-icons/sales-rep-blue.svg');
      }

      .sales-regions {
         background-image: url('../images/sidebar-icons/sales-regions-blue.svg');
      }

      .about {
         background-image: url('../images/sidebar-icons/about-blue.svg');
      }
   }


   .sidebar-icons-new {
      background-repeat: no-repeat;
      display: block;
      background-size: contain;
      height: 22px;
      width: 2rem;
   }

   .sidenav-link-icon {
      margin: 0 0.5rem;
   }
}