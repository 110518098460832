@import 'src/assets/scss/_variables.scss';

.sidenav {
    background-color: $white;
    transition: all .2s ease;
    position: fixed;
    z-index: 1040 !important;
    top: 0;
    width: 5rem;
    height: 100vh;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

    .logo-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding: 13px 27px 13px 26px;
        width: 100%;
        border-bottom: 2px solid #F1F1F1;

        .hamburger {
            border: 1px solid #dadada;
            display: inline-block;
            border-radius: 50%;
            padding: 10px;
            width: 30px;
            height: 30px;
            color: #081A51;
            display: flex;
            background: #fff;
            align-items: center;
            position: absolute;
            right: -12px;
            top: 48px;
            justify-content: center;
            cursor: pointer;
        }


        .logo-text {
            font-size: 24px;
            font-weight: 700;
            color: #fff;

            p {
                color: #64748B;
            }
        }

        .btn-close {
            margin-left: 3rem;
            cursor: pointer;
            width: 2rem;
            border-radius: 50%;
            background-color: transparent;
            border: none;

            .fa-solid {
                color: #fff;
                font-size: 24px;
            }
        }

        .fa-bars {
            color: #64748B;
        }
    }
}

.sidenav-collapsed {
    width: 16.5625rem;

    .logo-text img {
        height: 45px;
        transition: height 0.5s;
    }

    .hamburger i {
        transform: rotate(180deg);
    }

}

.sidenav-nav:hover {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 16rem);
}

.sidenav-nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: center;
    height: calc(100% - 16rem);
    overflow-y: auto;
    overflow-x: hidden;
    // cursor: pointer;

    .sidenav-nav-item {
        width: 100%;
        margin: 0.4rem 0 0.4rem 0;

        .sidenav-nav-link {
            display: flex;
            align-items: center;
            height: 3rem;
            color: #64748B;
            border-radius: 0;
            text-decoration: none;
            transition: all .2s ease;
            white-space: nowrap;
            border-left: 5px solid transparent;


            .sidenav-link-icon {
                // font-size: 20px;
                // width: 2rem;
                // min-width: 2rem;
                // margin: 0 0.5rem;
                // text-align: center;
            }

            .sidenav-link-text {
                // margin-left: 0.8rem;
                transition: all 0.5s ease;
            }
        }
    }

    .sidenav-nav-item:hover {
        .sidenav-nav-link {
            color: $primary-color;
            // transform: scale(1.1);
            // background-color: #FFFFFF;
        }
    }


    .active {
        // background-color: #ffff;
        border-left: 5px solid $primary-color !important;

        .sidenav-link-icon,
        .sidenav-link-text {
            color: $primary-color;
        }
    }
}

.custom-seperator {
    border-top: 2px solid #f1f1f1;
    margin: 0 10px 5px 0;
}

.visibility {
    visibility: hidden;
}