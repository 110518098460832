$primary-color: #2563EB;
$secondary-color: #7c7c7c;
$accent-color: #2563EB;
$success-color: #4bae6c;
$error-color: #D61F1F;
$glamour-pink: #FDF2F8;
$glamour-pink-text: #ED4F9D;
$white : #fff;


.c-F61C7A {
color: #F61C7A;
}
.c-94A3B8 {
    color: #94A3B8;
}