.location-search-icon {
    position: absolute;
    top: 7px;
    right: 15px;
    color: #bdbdbd;
}
.round-caret-icons {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EFF6FF;
    color: #2563EB;
    cursor: pointer;
    padding: 16px;
}
.location-search-input{
    width: 415px !important;
}
@media(max-width: 992px){
    .location-search-input{
        width: auto !important;
    }
}
.store-map-height{
    height: calc(100vh - 380px) !important;
}