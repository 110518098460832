.bg-image-vertical {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    }
    
   

.overlay2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-color; /* Adjust the opacity (0.5 in this case) to your desired value */
  }

  .login-text-2 {
    padding: 0 8rem;
    position: absolute;
    top: 40%;
    z-index: 1;
    h2 , p {
        color: #94A3B8;
    }
  }
  .diamond{
    position: absolute;
    left: 90px;
    bottom: 20px;
    z-index: 9;

  }


.main-logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 100px;
    z-index: 1; 
    h4 {
        font-weight: 700;
        font-size: 20px;
    }

}

.login-text {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 32px;
    border-radius: 10px;
    width: 550px;
    z-index: 1;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    font-size: 12px;
    background: #ffffff;
    color: rgb(7 34 78);
}

@media (max-width: 750px) { 
    .login-text {
    display: none;
    }
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Adjust the opacity (0.5 in this case) to your desired value */
  }

  .login-card{
    padding: 4rem 8rem;
  }
  @media (max-width:1330px){
    .login-card{
      padding: 4rem 6rem;
    }
  }
  @media (max-width:1230px){
    .login-card{
      padding: 4rem 3rem;
    }
  }