.dashboard-tickets .card .card-body {
    background-color: #EFF6FF;
}
.dashboard-tickets {
    .row  .col-lg-12{
        margin-bottom: 8px;

    }
    .card .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .top-brands{
        padding: 10px !important;
    }
}
.top-first{
    background-color: #017EFA !important;
}
.top-second{
    background-color: #30D987 !important;
}
.top-third{
    background-color: #feb01a !important;
}
.quick-report{
    background-color: #fafafa !important;
}
.card-list-bg-color{
    background-color: #EFF6FF;
    border-radius: 6px;
}
.appointment-cell{
    height: 69px !important;
}
.upcoming-event-scroller{
    max-height: calc(100vh - 43rem);
    overflow-x: hidden;
    overflow-y: auto;
}
.circle-icon{
    color: #017EFA !important;
  }

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
    background: #fff !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
    color: #242a33 !important;
    border: 1px solid #081A51 !important;
    border-radius: 3px !important;

}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #081A51 !important;
    background: #081A51 !important;
    margin-bottom: 2px !important;
    border-radius: 4px !important;
    padding: 0 9px !important;

}
.multiselect-item-checkbox:hover {
    background-color: #EFF6FF !important;
}



// Daily Diamond scss 
// Daily Diamond scss 

.user-image {
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color: #fff;
    border: #017EFA solid 1px ;


    img {
        width: 100%;
        height: auto;
        display: block;
        cursor: pointer;
    }
}
// .daily-diamond-image {
//     border-radius: 50%;
//     overflow: hidden;
//     width: 45px;
//     height: 45px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: #fff;
//     border: #081a51 solid 1px ;

//     img {
//         width: 35px;
//         height: auto;
//         display: block;
//         cursor: pointer;
//         margin-top: 5px;
//     }
// }


.queries-input.form-control-lg {
        padding: 0.5rem 3rem 0.5rem 1rem !important; 
}
.daily-diamonds-scroller-main{
    height: calc(100vh - 630px);
    overflow-x: hidden;
    overflow-y: auto;
}
.daily-diamonds-scroller{
    max-height: calc(100vh - 41.9rem);
    overflow-x: hidden;
    overflow-y: auto;
}
.message-icon{
    color: #081a51 !important;

}
// Daily Diamond scss end
// Daily Diamond scss end

// splash screen

.splash-screen {
    height: calc(100vh - 120px);
  }
  .font-150 {
    font-size: 9.4rem;
    line-height: 140px;
    color: #19213d !important;
  }
  @media (max-width: 885px) {
    .font-150 {
        font-size: 4.4rem !important;
        line-height: 70px !important;
    }
}
  .sub-heading {
    font-size: 30px;
    line-height: 36px;
    color: #6d758f;
    padding-left: 12px;
  }
  @media (max-width: 885px) {
    .sub-heading {
        padding-left: 0; /* Remove the padding-left property */
    }
}
  .start-diamond {
    width: 350px !important;
    border-radius: 50px;
  }
  
  .splash-screen-card {
      background: rgb(255,255,255);
      background: linear-gradient(32deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 81%, rgba(183,216,255,1) 100%, rgba(196,223,255,1) 100%, rgba(141,194,255,1) 100%, rgba(196,223,255,1) 100%, rgba(80,161,255,1) 100%, rgba(255,255,255,1) 100%);
  }
.store-sales{
    ng-select {
        width: 160px !important;
    }
}
