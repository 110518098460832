.table>:not(caption)>*>* {
    padding: 14px 14px !important;
   }
   app-bling-bot-chat table{
    margin-bottom: 10px !important;
    thead{
      position: unset !important;
    }
   }
table {
    thead {
        position: sticky;
        top: 0;
        z-index: 1;
        border-radius: 12px !important;
        tr {
          th:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          th:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius:8px;
          }
        }
    }
    th {
        white-space: nowrap;
        font-weight: 400 !important;
        // background-color: #F8FAFC !important;
        color: #8B909A !important;
    }
    td {
      color: #23272E !important;

    }
}
.stores-table {
    max-height: calc(100vh - 440px);
    overflow: auto !important;
}

.table-height {
  height: calc(100vh - 400px);
  overflow: auto !important;
}
app-tenant-info .table-height {
  height: calc(100vh - 445px);
  overflow: auto !important;
}

@media (max-width: 1989px){
  .table-height {
    height: calc(100vh - 440px);
    overflow: auto !important;
  }
}
