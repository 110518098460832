@import 'src/assets/scss/_buttons.scss';
@import 'src/assets/scss/_authentication.scss';
@import 'src/assets/scss/_fonts.scss';
@import 'src/assets/scss/_header.scss';
@import 'src/assets/scss/_sidebar.scss';
@import 'src/assets/scss/_variables.scss';
@import 'src/assets/scss/_form.scss';
@import 'src/assets/scss/_alerts.scss';
@import 'src/assets/scss/_survey.scss';
@import 'src/assets/scss/modals.scss';
@import 'src/assets/scss/_dashboard.scss';
@import 'src/assets/scss/_spacing.scss';
@import 'src/assets/scss/_list-card.scss';
@import 'src/assets/scss/_stores.scss';
@import 'src/assets/scss/_table.scss';
@import 'src/assets/scss/reports.scss';
@import 'src/assets/scss/checkboxes.scss';
@import 'src/assets/scss/_loader.scss';
@import 'src/assets/scss/_masonry.scss';
@import 'src/assets/scss/_colors.scss';
@import 'src/assets/scss/_icons.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');

/* You can add global styles to this file, and also import other style files */
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   color: #0F172A !important;
}

h1 {
   font-size: 30px !important;
   line-height: 36px !important;
}

h2 {
   font-size: 24px !important;
   line-height: 34px !important;
}

h3 {
   font-size: 20px !important;
   line-height: 24px !important;
}

.span-text-color {
   color: #0F172A !important;
}

.cursor {
   cursor: pointer !important;
}



.bs-datepicker-body table thead {
   position: unset !important;
}

.bs-datepicker-body table th {
   background-color: transparent !important;
   color: #081A51 !important;
   font-weight: 400 !important;
   border-left: none !important;
   white-space: inherit !important;
}

.theme-green .bs-datepicker-head {
   background-color: $primary-color !important;

}

.theme-green .bs-datepicker-body table td span.selected {
   background-color: $primary-color !important;
}

.theme-green .bs-datepicker-body table td.week span {
   color: $primary-color !important;
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
   transform: none;
   // top: 65px;
   z-index: 1051 !important;
}

.dropdown-toggle::after {
   display: none !important;
}

.dropdown-menu.show {
   box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
   border: none !important;
   padding: 0 0 !important;

}

.dropdown-item:hover,
.dropdown-item:focus {
   color: #0F172A !important;
   background-color: #EFF6FF !important;
   // border-radius: 0 0 6px 6px !important;
}

.custom-dropdown ::ng-deep .multiselect-dropdown-placeholder {
   /* Customize the placeholder styles */
   color: red;
   font-weight: bold;
   /* Add any other styles you want */
}


::placeholder {
   color: #00000042 !important;
   font-size: 14px !important;
}

.tabs-section {
   .nav-tabs .nav-link.active {
      border-bottom: 2px solid $primary-color !important;
      color: $primary-color !important;
      border: none;
      background-color: transparent !important;
      font-weight: 500 !important;
      font-size: 16px !important;
   }

   .nav-tabs .nav-link {
      border: none !important;
      color: #41464b !important;
      font-weight: 500 !important;
      font-size: 16px !important;

   }
}

.navs-padding {
   padding: 10.5px 0 0;
}

.list-group {
   .badge {
      background-color: transparent !important;
      color: #D61F1F !important;
      padding: 0 !important;
      font-size: 20px !important;
   }
}

.page-link.active,
.active>.page-link {
   color: #fff !important;
   background-color: $primary-color !important;
   border-color: $primary-color !important;
}

.page-link {
   color: $primary-color !important;
}

ul.pagination {
   margin: 0 !important;
   padding: 10px 0;
}

::-webkit-scrollbar {
   width: 5px;
   height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
   background: #c4c3c3;
   border-radius: 2px;

}

/* custom radios */
.custom-radio {
   display: flex;
   cursor: pointer;
   font-weight: 500;
   position: relative;
   overflow: hidden;
   margin-bottom: 0.375em;
}

.custom-radio input {
   position: absolute;
   left: -9999px;
}

.custom-radio input:checked+span:before {
   box-shadow: inset 0 0 0 0.4375em #081A51;
}

.custom-radio span {
   display: flex;
   align-items: center;
   padding: 0.375em 0.75em 0.375em 0.375em;
   border-radius: 99em;
   transition: 0.25s ease;
}

.custom-radio span:before {
   display: flex;
   flex-shrink: 0;
   content: '';
   background-color: #fff;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   margin-right: 0.375em;
   transition: 0.25s ease;
   box-shadow: inset 0 0 0 0.125em #081A51;
}

.page-link:focus {
   box-shadow: 0 0 0 0 !important;
}

.e-schedule .e-timeline-year-view .e-header-cells,
.e-schedule .e-timeline-year-view .e-work-cells {
   width: 23px !important;
}


.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
   background: #0d6efd !important;
   color: #fff !important;
}

.anychart-credits {
   display: none !important;
}

.cursor-pointer {
   cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
   font-weight: 500 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
   box-shadow: none !important;
   border-color: $accent-color !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
   color: #2563EB !important;
   background-color: #EFF6FF !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
   padding: 0 0 0 8px !important;
   color: #2563EB !important;
   font-weight: 500 !important;
   background-color: #EFF6FF !important;
}

.ng-select.ng-select-single .ng-select-container {
   height: 36px !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:after {
   background-color: transparent !important;
   top: 50%;
   left: 3px !important;
   width: 8.5px !important;
   height: 3.5px !important;
   margin-top: -5px !important;
   border-style: solid;
   border-color: #081a51 !important;
   border-width: 0 0 2px 2px !important;
}

.loader-height {
   height: calc(100vh - 330px);
   display: flex;
   justify-content: center;
   align-items: center;
}

.reports-loader {
   height: calc(100vh - 548px);
   display: flex;
   justify-content: center;
   align-items: center;
}

.reports-loader-vendor {
   height: calc(100vh - 58rem);
   display: flex;
   justify-content: center;
   align-items: center;
}

.loader-height-region {
   min-height: calc(100vh - 275px) !important;
   display: flex;
   justify-content: center;
   align-items: center;
}

.diamond-loader-view {
   background-image: url('./assets/images/diamond.svg');
   background-repeat: no-repeat;
   display: block;
   width: 35px !important;
   height: 37px !important;
   background-size: contain;
   position: relative;
   right: 0;
   top: 2px;
}

.apexcharts-toolbar {
   z-index: 1 !important;
}

model-viewer {
   width: 100% !important;
   height: calc(100vh - 160px);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
   flex-wrap: nowrap !important;
}

.fa-eye,
.fa-eye-slash {
   cursor: pointer;
}

.my-tb-class .tooltip-inner {
   width: 100px;
   background-color: #017efa;

}

.my-tb-class.bs-tooltip-top .tooltip-arrow::before {
   border-top-color: #017efa;
}

.my-ti-class .tooltip-inner {
   width: 100px;
   background-color: #2fd987;
}

.my-ti-class.bs-tooltip-top .tooltip-arrow::before {
   border-top-color: #2fd987;
}

.my-ni-class .tooltip-inner {
   width: 100px;
   background-color: #feb019;

}

.my-ni-class.bs-tooltip-top .tooltip-arrow::before {
   border-top-color: #feb019;
}

.carousel-control-next,
.carousel-control-prev {
   z-index: 0 !important;
}

.carousel-control-next .carousel-control-next-icon,
.carousel-control-prev .carousel-control-prev-icon {
   background-color: #6c739096;
   width: 2rem;
   height: 3rem;

}

.popParent ngb-popover-window {
   padding: 0;
   cursor: default;
   border: 1px solid #081A51;
}

.form-control-input-values {
   font-family: 'Roboto', sans-serif !important;
   font-size: 15px !important;
}

.survey-pie .apexcharts-legend-series .apexcharts-legend-text {
   width: 160px !important;
   display: inline-block !important;
   white-space: nowrap !important;
   overflow: hidden !important;
   text-overflow: ellipsis !important;
}

.default-cursor {
   cursor: auto;
}

.page-link.active,
.active>.page-link {
   z-index: 1 !important;
}

.cursor-unset {
   cursor: unset !important;
}


.envelope {
   background-image: url('./assets/images/icons/envelope.png');
   background-repeat: no-repeat;
   width: 25px;
   height: 20px;
   position: absolute;
   top: 16px;
   left: 16px;
}
.user-icon{
   width: 27px;
   height: 27px;
   position: absolute;
   top: 16px;
   left: 16px;
   color: #8693a9;
   font-weight: 500;
}

.lock {
   background-image: url('./assets/images/icons/lock.png');
   background-repeat: no-repeat;
   width: 20px;
   height: 20px;
   position: absolute !important;
   left: 18px;
   top: 12px;
}

.tenant {
   background-image: url('./assets/images/icons/tenant.png');
   background-repeat: no-repeat;
   width: 20px;
   height: 20px;
   position: absolute !important;
   left: 17px;
   top: 12px;
}

@media (max-width: 769px) {
   .ng-option-label {
      font-size: 12px;
   }
}

.primary-text {
   color: $primary-color;
}

.storeLocationImg {
   .carousel-control-next {
      right: -18px !important;
   }

   .carousel-control-prev {
      left: -17px !important;
   }

}

.apexcharts-menu-item {
   text-align: center;
}

.apexcharts-toolbar {
   margin-top: 5px;
}

app-users .user-table-height {
   height: calc(100vh - 435px);
   overflow: auto;
}

@media(max-width: 1989px) {
   app-users .user-table-height {
      height: calc(100vh - 460px) !important;
      overflow: auto;
   }
}

@media(max-width: 1225px) {
   app-users .user-table-height {
      height: calc(100vh - 535px) !important;
      overflow: auto;
   }
}

@media(max-width: 1164px) {
   app-users .user-table-height {
      height: calc(100vh - 580px) !important;
      overflow: auto;
   }
}

@media(max-width: 992px) {
   app-users .user-table-height {
      height: calc(100vh - 535px) !important;
      overflow: auto;
   }
}

@media(max-width: 979px) {
   app-users .user-table-height {
      height: calc(100vh - 580px) !important;
      overflow: auto;
   }
}
app-tenants .user-table-height {
   max-height: calc(100vh - 525px) !important;
   overflow: auto;
}
app-tenants .user-table-height1 {
   max-height: calc(100vh - 325px) !important;
   overflow: auto;
}

@media(max-width: 1989px) {
   app-tenants .user-table-height {
      height: 54rem;
      max-height: calc(100vh - 575px) !important;
      overflow: auto;
   }
}

@media (max-width: 1225px) {
   app-tenants .user-table-height {
      max-height: calc(100vh - 570px) !important;
      overflow: auto;
   }
}

@media (max-height: 760px){
   app-tenants .user-table-height {
       max-height: calc(100vh - 420px) !important;
       overflow: auto;
   }
}

.daily-diamond {
   .table> :not(caption)>*>* {
      background-color: #fafafa !important;
   }
}

.cal-month-view .cal-day-badge {
   background-color: #2664ec !important;
}

.cal-month-view .cal-open-day-events {
   color: #909294 !important;
   background-color: #ffffff !important;
   box-shadow: none !important;
}

.report-values {
   font-weight: bold;
   line-height: 20px !important;
}

.ngselect-custom-w {
   .ng-select-container {
      width: 120px !important;
   }
}

.ngselect-custom-width {
   .ng-select-container {
      width: 165px !important;
   }
}

ngb-carousel img {
   width: 100%;
   object-fit: cover;
}

.carousel-control-prev,
.carousel-control-next {
   height: 40px !important;
   top: 45% !important;
}

.border-red {

   ng-select.ng-invalid.ng-touched .ng-select-container,
   .survey-input.ng-invalid.ng-touched {
      border: 1px solid #ff0000;
      border-radius: 4px;
   }
}

.object-fit {
   object-fit: cover;
   width: 50px !important;
   height: 50px !important;
   border-radius: 50%;
   border: 1px solid #007fff;
}

.line-height {
   line-height: 22px !important;
}

.btn-close:focus {
   box-shadow: none !important;
}

.bulkUploadLoader {
   padding: 0px 15px;
}

.slick-next:before,
.slick-prev:before {
   color: #000 !important;
}

.slick-prev {
   left: -10px !important;
}

.slick-next {
   right: -10px !important;
}

.e-content-wrap {
   min-height: 550px !important;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
   border-top: 1px solid #dddddd !important;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
   opacity: 0.7;
}

.w-240 {
   width: 240px;
}

.w-200 {
   width: 230px;
}

.chat-Table-heigth {
   max-height: calc(100vh - 600px);
   overflow: auto;
}

.margin-calculator-height {
   height: calc(100vh - 330px);
   overflow: auto;
}

.ng-dropdown-panel {
   z-index: 400 !important;
}

bs-datepicker-container,
bs-daterangepicker-container {
   z-index: 9999 !important;
}

.popover {
   z-index: 1039 !important;
}

.popover-body {
   width: 175px !important;
}

.color-red {
   color: red !important;
}

.table-icon-color {
   color: #2563EB;
}

.carousel .slick-slide {
   display: flex !important;
   justify-content: center !important;

}

.custom-carousel .carousel-control-next-icon,
.custom-carousel .carousel-control-prev .carousel-control-prev-icon {
   background-color: transparent !important;
}

.custom-carousel .carousel-indicators {
   display: none !important;
}

.custom-carousel .carousel-item:not(.active) {
   display: none !important;
   width: 42px !important;
}

.custom-carousel .carousel-inner {
   position: relative;
   width: 42px !important;
   height: 42px !important;
   overflow: hidden;
}

.admin-csv-icon{
   border: 1px solid #2664ec;
   padding: 5px;
   font-size: 18px;
   border-radius: 6px;
   background-color: #fff;
   color: #2664ec;
   cursor: pointer;
}
// app-bling-bot-chat thead, tbody, tfoot, tr, td, th {
//    border-width: 1px !important;
//    border-radius: 12px !important;
//    th{
//       background-color: #ffff !important;
//       color: #000 !important;
//       font-weight: 700 !important;
//       padding: 0 5px !important;
//    }
//    td{
//       padding: 0 5px !important;
//    }
// }
app-bling-bot-chat table {
   border-collapse: collapse;
}

app-bling-bot-chat table thead, 
app-bling-bot-chat table tbody, 
app-bling-bot-chat table tfoot, 
app-bling-bot-chat table tr, 
app-bling-bot-chat table td, 
app-bling-bot-chat table th {
   border: 1px solid #000 !important;
   border-radius: 12px !important;
}

app-bling-bot-chat table th {
   background-color: #fff !important;
   color: #000 !important;
   font-weight: 700 !important;
   padding: 0 5px !important;
}

app-bling-bot-chat table td {
   padding: 0 5px !important;
}
