.bg-available {
  background-color: #E9EFFD;
  color: #2563EB;
}

.bg-unavailable {
  background-color: $glamour-pink;
  color: $glamour-pink-text;
}

.available-reports {
  // background-color: #e9effd;
  border-radius: 8px;
  // color: #2563eb;
  width: max-content;
}

.inner-card {
  background-color: #f8fafc !important;
}

.report-time-color {
  color: #64748B;
}

.cases-percent {
  background-color: #F0FDF4;
  color: #24D164;
  padding: 12px 8px;
  font-size: 12px;
  // border-radius: 50%;
  // width: max-content;
}

.ticket-text-color {
  color: #64748B;
}

.ng-select .ng-clear-wrapper {
  display: none !important;
}

app-previous-promotional-flyers .ng-select .ng-clear-wrapper {
  display: block !important;
}

app-flyers-marketing .ng-select .ng-clear-wrapper {
  display: block !important;
}
app-container .ng-select .ng-clear-wrapper {
  display: block !important;
}

@media(max-width:1199px) {
  .chart-bottoms {
    margin-bottom: 16px;
  }
}