@import 'src/assets/scss/_variables.scss';

/* ------------------------------------- Survey Css------------------------------------*/
/* ------------------------------------- Survey Css------------------------------------*/

.survey.card .card-header{
    background-color: transparent !important;
  }
  
  .survey label{
  color: #455a64 !important;
  }
  
  .survey-scroller-height{
  height: calc(100vh - 178px);
  overflow-x: hidden;
  overflow-y: auto;
  }
  
  .survey .fa-circle-check{
  font-size: 100px;
  color: #4fdb49;
  }
  
  .submission-text{
    color: #4fdb49;
  }
  
  .survey textarea{
  resize: none !important;
  }
  
  .survey-switch a{
  color: $primary-color !important;
  }
  
  .survey-responses{
  width: 251px !important;
  transition: transform 1.1s ease !important;
  cursor:  pointer !important;
  a {
    text-decoration: none !important;
    color: $primary-color;
  }
  }
  .survey-responses:hover{ 
  background-color: $primary-color !important;
  transform: scale(1.16) !important;
  }
  
  .survey-responses:hover .response-link {
    color: #fff !important;
  }
  
  .appearance-table-scroller{
    overflow: auto !important;
    max-height: calc(100vh - 574px) !important;
  }
  
  .survey .appearance-table-scroller thead{
    position: sticky !important;
    top: -1px !important;
    z-index: 1 !important;
  }
  .survey-calander-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    pointer-events: none;
    z-index: 1;
  }
  .progress {
    height: 17px !important;
    width: 220px !important;
    border-radius: 20px !important;
    .bg-primary {
       background-color: $primary-color !important;
    }
 }
 
 .items-section input, .items-section label{
    cursor: pointer !important;
 }
.loader-height-survey{
  height: calc(100vh - 272px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.items-section input, .items-section label.disable-cursor{
  cursor: unset !important;
}
.survey-response-text{
  color: #2388FF;
}
.survey-response{
  font-size: 36px;
  color: #141C24 !important;
}
@media(max-width:810px){
  .items-desc{
    margin-left: 71px;
  }
}

  .custom-circle {
    height: 50px;
    width: 50px;
    border: 1px solid #F8FAFF;
    display: flex;
    background: #F8FAFF;
    align-items: center;
    cursor: auto;
    border-radius: 50%;
    justify-content: center;
  }
  /* ------------------------------------- Survey Css------------------------------------*/
  /* ------------------------------------- Survey Css------------------------------------*/
  