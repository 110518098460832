.flipchar {
  display: inline-block;
  // animation: lds-circle 10s cubic-bezier(0, 0.2, 0.8, 1) infinite;;
  color: #f66e49;
}

@keyframes lds-circle {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.diamond-loader {
  // background-image: url('../../../../../../assets/images/diamond.svg');
  background-image: url('../../assets/images/turbo-nloader.gif');
  background-repeat: no-repeat;
  display: block;
  width: 120px;
  height: 120px;
  background-color: transparent !important;
  background-size: contain;
  // background-color: #fff;
  z-index: 5;
  // position: fixed;
  position: absolute;
  top: 50%;
  left: 50%;
  background-position: center;
  // left: calc(50% + 40px) !important;
  // transform: translate(-50%, -50%);
}

app-administration .diamond-loader{
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

app-bling-bot-chat .diamond-loader, app-help-center-container .diamond-loader{
  margin-left: 16rem !important;
  left: 50% !important;
}
@media(max-width: 992px){
  app-bling-bot-chat .diamond-loader, app-help-center-container .diamond-loader{
    margin-left: 10.6rem !important;
    left: 50% !important;
  }
}

// .body-trimmed .diamond-loader{
//   left: calc(50% + 150px) !important;

// }
// @media (max-width:992px){
//   .diamond-loader{
//     left: calc(50% + 40px) !important;
//   }
// }

app-business-summary,
app-daily-diamonds,
app-events,
app-report-list,
app-top-performers,
.modal-dialog {
  .diamond-loader {
    position: unset;
    transform: none !important;
  }
}

app-available-items .diamond-loader,
app-view-all-performers .diamond-loader,
app-about .diamond-loader,
app-users .diamond-loader,
app-tenants .diamond-loader,
app-view-daily-diamonds .diamond-loader,
app-report-list .diamond-loader,
app-store-locations .diamond-loader,
app-flyers-marketing .diamond-loader,
app-table .diamond-loader,
app-scheduler-details .diamond-loader {
  background-color: transparent !important;
}

app-scheduler-details .diamond-loader{
  top: 57% !important;
  // left: calc(50% + 270px) !important;
}
app-flyers-marketing .diamond-loader{
  // width: 120px;
  // height: 120px;
  top: 15%;
}

app-previous-promotional-flyers .diamond-loader{
  top: 35%;
}
app-available-items .diamond-loader,
app-about .diamond-loader,
app-settings .diamond-loader,
app-view-daily-diamonds .diamond-loader
{
  left: 56%;
}
// app-files .diamond-loader
// {
//   left: 40% !important;
//   top: 56% !important;
// }

app-store-locations .diamond-loader{
  top: 61% !important;
  left: 48% !important;
}

app-files .diamond-loader{
  top: 79% !important;
  left: 41% !important;
}

app-report-email .diamond-loader{
  position: absolute !important;
  top: 16px !important;
  right: 10px !important;
  width: 90px !important;
  height: 60px !important;
}

// app-vendor-reports .diamond-loader{
//  height: 35px !important;
//  width: 35px !important;
// }

// .btn-loader{
//   position: absolute !important;
//   top: 16px !important;
//   right: 10px !important;
//   width: 90px !important;
//   height: 60px !important;
// }
// .btn-loaders .diamond-loader{
//   position: absolute !important;
//   top: 13px !important;
//   right: 10px !important;
//   width: 90px !important;
//   height: 60px !important;
//   left: 88% !important;
// }
// app-header .diamond-loader{
//   top: 6px !important;
//   left: 40px !important;
// }

