.normal-no-container {
    padding: 4px 10px;
    background: #EFF6FF !important;
    border-radius: 4px;
    font-weight: 500;
    color: #081a51;
    display: flex;
    // width: 60px;
    justify-content: center;
}
.negative-no-container {
    padding: 4px 10px;
    background: #ae4b4b3d;
    border-radius: 4px;
    font-weight: 500;
    color: rgb(179, 0, 0);
    display: flex;
    // width: 60px;
    justify-content: center;
}
.positive-no-container {
    padding: 4px 10px;
    background: #4bae6c3d;
    border-radius: 4px;
    font-weight: 500;
    color: green;
    display: flex;
    // width: 60px;
 
    justify-content: center;
}