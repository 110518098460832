@import 'src/assets/scss/_variables.scss';

.form-control:focus , .form-select:focus {
    border-color: $accent-color !important;
    box-shadow: 0 0 0 0 !important;
}

.form-check-input:checked {
    background-color: $accent-color !important;
    border-color: $accent-color !important;

    &:focus {
        box-shadow: 0 0 0 0 !important;
    }
}

a {
    text-decoration: none !important;
}