.font-10{ font-size: 10px !important; }
.font-12{ font-size: 12px !important; }
.font-14{ font-size: 14px !important; }
.font-16{ font-size: 16px !important; }
.font-18{ font-size: 18px !important; }
.font-20{ font-size: 20px !important; }
.font-22{ font-size: 22px !important; }
.font-24{ font-size: 24px !important; }
.font-26{ font-size: 26px !important; }
.font-28{ font-size: 28px !important; }
.font-30{ font-size: 30px !important; }
.font-36 { font-size: 36px !important;}
.font-40 { font-size: 40px !important;}
.font-120{ font-size: 120px !important;} 
/* font weight */

.font-100 {
    font-weight: 100 !important;
}

.font-200 {
    font-weight: 200 !important;
}

.font-300 {
    font-weight: 300 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.font-600 {
    font-weight: 600 !important;
}

.font-700 {
    font-weight: 700 !important;
}

.font-800 {
    font-weight: 800 !important;
}

