ngx-masonry {
    position: relative !important;
    width: 100% !important;
}

.masonry-item {
    // width: 300px !important;
    margin-bottom: 15px;
    margin-right: 10px;
}

@media (max-width:1590px) {
    .masonry-item {
        width: 280px;
    }
}

@media (max-width:1520px) {
    .masonry-item {
        width: 270px;
    }
}

@media (max-width:1480px) {
    .masonry-item {
        width: 260px;
    }
}

@media (max-width:1440px) {
    .masonry-item {
        width: 250px;
    }
}

@media (max-width:1400px) {
    .masonry-item {
        width: 520px;
    }
}

@media (max-width:1399px) {
    .masonry-item {
        width: 510px;
    }
}

@media (max-width:1380px) {
    .masonry-item {
        width: 500px;
    }
}

@media (max-width:1360px) {
    .masonry-item {
        width: 490px;
    }
}

@media (max-width:1340px) {
    .masonry-item {
        width: 480px;
    }
}

@media (max-width:1320px) {
    .masonry-item {
        width: 470px;
    }
}

@media (max-width:1300px) {
    .masonry-item {
        width: 460px;
    }
}

@media (max-width:1280px) {
    .masonry-item {
        width: 450px;
    }
}

@media (max-width:1260px) {
    .masonry-item {
        width: 440px;
    }
}

@media (max-width:1240px) {
    .masonry-item {
        width: 430px;
    }
}

@media (max-width:1220px) {
    .masonry-item {
        width: 420px;
    }
}

@media (max-width:1200px) {
    .masonry-item {
        width: 410px;
    }
}

@media (max-width:1180px) {
    .masonry-item {
        width: 400px;
    }
}

@media (max-width:1160px) {
    .masonry-item {
        width: 390px;
    }
}

@media (max-width:1140px) {
    .masonry-item {
        width: 380px;
    }
}

@media (max-width:1120px) {
    .masonry-item {
        width: 370px;
    }
}

@media (max-width:1100px) {
    .masonry-item {
        width: 360px;
    }
}

@media (max-width:1080px) {
    .masonry-item {
        width: 350px;
    }
}

@media (max-width:1060px) {
    .masonry-item {
        width: 340px;
    }
}

@media (max-width:1040px) {
    .masonry-item {
        width: 330px;
    }
}

@media (max-width:1020px) {
    .masonry-item {
        width: 320px;
    }
}

@media (max-width:1000px) {
    .masonry-item {
        width: 100%;

        ngb-carousel {
            img {
                height: 400px;
            }
        }
    }
}