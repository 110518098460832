@import 'src/assets/scss/_variables.scss';

.modal-title {
    font-size: 22px;
    font-weight: 500;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    overflow-x: hidden !important;
}

input[type="radio" i]:focus-visible {
    outline-offset: 0px !important;
    outline: 0 !important;
}

.modal-close-btn {
    background: transparent;
    color: $primary-color;
    border: 0;
    padding: 3px 9px;
    border-radius: 4px;
    display: none;
    &:hover {
        background-color: transparent;
        color: $primary-color;
        border-color: $primary-color;
    }
}