@import 'src/assets/scss/_variables.scss';

.btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  transition: 400ms !important;



  i {
    color: white;
    font-weight: 700;
    font-size: 1em;
    transition: 400ms;
  }

  &:hover {
    background-color: transparent !important;
    border-color: $primary-color !important;
    color: $primary-color !important;

  }
}

.btn-primary:hover .text,
.btn-primary:hover i {
  color: $primary-color !important;
}




// Secondary btn
.btn-secondary {
  background-color: transparent !important;
  border-color: $secondary-color !important;
  transition: 400ms !important;
  color: $secondary-color !important;



  i {
    color: $secondary-color;
    font-weight: 700;
    font-size: 1em;
    transition: 400ms;
  }

  &:hover {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
    color: #fff !important;

  }
  
}

// success btn
.btn-success {
  background-color: transparent !important;
  border-color: $success-color !important;
  transition: 400ms !important;
  color: $success-color !important;


  i {
    color: $success-color;
    font-weight: 700;
    font-size: 1em;
    transition: 400ms;
    
  }

  &:hover {
    background-color: $success-color !important;
    border-color: $success-color !important;
    color: #fff !important;
  }
}
.btn-success:hover i {
  color: #fff !important;
}

.modal-header .btn-close:focus {
  box-shadow: 0 0 0 0 !important;
}

// btn danger 

.btn-danger  {
  background-color: $error-color !important;
  border-color: $error-color !important;
  transition: 400ms !important;



  i {
    color: white;
    font-weight: 700;
    font-size: 1em;
    transition: 400ms;
  }

  &:hover {
    background-color: transparent !important;
    border-color: $error-color !important;
    color: $error-color !important;

  }
}

.btn-danger:hover i {
  color: $error-color !important;
}


.btn-shades {
  padding: 6px 10px;
  border-radius: 6px;
  background: #FDF2F8;
  line-height: 16px;
  color: #F61C7A;
  border-color: transparent;
  white-space: nowrap;
  transition: background-color 0.5s ease; /* Add a transition for background-color property */

  &:hover {
    background: #f61d7b;
    color: #fff;
  }

}

.btn-default:active{
  border: 0 !important;
}